<template>
    <div>

        <CRow>
            <CCol lg="3">
                <CInput
                    type="date"
                    name="action"
                    v-model="conditions.created_date"
                    required
                    @input="getResults"
                />
            </CCol>
        </CRow>


        <CRow style="position: relative">
            <CCol>

                <vue-good-table
                        :columns="time_ids"
                        :rows="regions"
                        >

                </vue-good-table>
            </CCol>

            <div class="is-loading" v-if="!conditions.data_loaded">
                <img src="/img/spinner.svg" width="80" height="80" alt="">
            </div>
        </CRow>
    </div>
</template>

<script>
    import axios from "axios";
    import store from "../../store";
    import { VueGoodTable } from 'vue-good-table';


    import 'vue-good-table/dist/vue-good-table.css'
    import 'vue-search-select/dist/VueSearchSelect.css'

  const formatYmd = (date) => {
      return date.toISOString().slice(0, 10)
  };

  let tzoffset = (new Date()).getTimezoneOffset() * 60000;
  let today = formatYmd(new Date(Date.now() - tzoffset));
  let regions = "";
  let total = 0;

export default {
    name: 'Monitor_5',
    data: function () {
      return {
        conditions:
          {
              created_date: today,
              data_loaded: false
          },
        time_ids: [
            {
                field: "name",
                label: "Обл",
            },
            {
                field: "30",
                label: "5:00",
            },
            {
                field: "33",
                label: "11:00",
            },
            {
                field: "36",
                label: "17:00",
            },
            {
                field: "42",
                label: "23:00",
            },
        ],
        regions: [
            {
                30: "...",
                33: "...",
                36: "...",
                42: "...",
                id: "26",
                name: "Фер",
            },
            {
                id: "28",
                name: "Нам",
            },
            {
                id: "23",
                name: "Анд",
            },
            {
                id: "27",
                name: "Крг",
            },
            {
                id: "29",
                name: "Общ",
            },
            {
                id: "total",
                name: "Итого",
            },
        ],
      }
    },
    mounted: function () {
        this.regions.forEach((el, index) => {
            if (el.id !== "total") {
                if (index === 0) {
                    regions += el.id;
                } else {
                    regions += "," + el.id;
                }
            }
        });
        this.getResults();
    },
    methods: {
        getResults() {
            this.conditions.data_loaded = false;
            this.time_ids.forEach((el_t) => {
                if (el_t.field !== "name" ) {
                    axios
                        .get(`${axios.defaults.baseURL}?action=get_results_by_date_time_id_region_data_type&created_date=${this.conditions.created_date}&time_id=${el_t.field}&data_type=9&region_id=${regions}`)
                        .then((results_response) => {
                            this.conditions.data_loaded = true;
                            total = 0;
                            this.regions.forEach((el) => {
                                if (el.id !== "total" && results_response.data[el.id] !== undefined)
                                {
                                    el[el_t.field] = results_response.data[el.id];
                                    total += results_response.data[el.id];
                                } else {
                                    el[el_t.field] = total;
                                }
                            })
                        })
                }
            })
        },
    },

    components: {
        VueGoodTable
    }

  }
</script>


<style>
    .container-fluid {
        height: 100%;
    }
    .is-loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,.7);
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
